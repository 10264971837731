import * as z from 'zod'

export const MainAdminID = 1

export function typeObjectKeys<T extends string>(obj: Record<T, any>) {
	return Object.keys(obj) as [T, ...T[]]
}

/**
 * 游戏类型
 */
export const GameTypes = [
	/* 电子游戏 */
	'ELECTRONIC',
	/* 棋牌游戏 */
	'CHESS',
	/* 捕鱼游戏 */
	'FISHING',
	/* 视讯游戏 */
	'VIDEO',
	/* 体育游戏 */
	'SPORTS',
	/* 彩票游戏 */
	'LOTTERY',
] as const

export const AllGameTypes = ['all', ...GameTypes] as const

export type GameType = (typeof GameTypes)[number]
export const ZGameType = z.enum(AllGameTypes)
export type TAllGameTypes = (typeof AllGameTypes)[number]

/*
 * platform 状态
 */
export const PlatformStatusList = [
	/** 关闭 */
	'OFF',
	/** 开启 */
	'ON',
	/** 维护 */
	'MAINTAIN',
] as const
export type PlatformStatus = (typeof PlatformStatusList)[number]
export const ZPlatformStatus = z.enum(['all', ...PlatformStatusList])

/*
 * game 经营类型
 */
export const GameBusinessTypeList = [
	/** 自营 */
	'OWN',
	/** 三方 */
	'THIRD',
] as const
export type GameBusinessType = (typeof GameBusinessTypeList)[number]
export const ZGameBusinessType = z.enum(['all', ...GameBusinessTypeList])

/*
 * platform 经营类型
 */
export const PlatformBusinessTypeList = [
	...GameBusinessTypeList,
	/** 自营 和 三方 */
	'OWN_THIRD',
] as const
export type PlatformBusinessType = (typeof PlatformBusinessTypeList)[number]
export const ZPlatformBusinessType = z.enum([...PlatformBusinessTypeList])

/*
 * platform 状态
 */
export const PlatformTargetList = [
	/** 游戏列表 */
	'gameList',
	/** 大厅 */
	'hall',
] as const
export type TPlatformTargetList = (typeof PlatformTargetList)[number]
export const ZPlatformTargetList = z.enum(['all', ...PlatformTargetList])

/*
 * GameType 状态
 */
export const GameTypeStatusList = [
	/** 关闭 */
	'OFF',
	/** 开启 */
	'ON',
	/** 维护 */
	'MAINTAIN',
] as const

export type GameTypeStatus = (typeof GameTypeStatusList)[number]
export const ZGameTypeStatus = z.enum(['all', ...GameTypeStatusList])

/*
 * game 状态
 */
export const GameStatusList = [
	/** 关闭 */
	'OFF',
	/** 开启 */
	'ON',
	/** 维护 */
	'MAINTAIN',
] as const

export type GameStatus = (typeof GameStatusList)[number]
export const ZGameStatus = z.enum(['all', ...GameStatusList])

/*
 * game 有效投注的计算方式
 */
export const ValidBetTypeList = [
	/** 输赢 */
	'PROFIT',
	/** 投注 */
	'BET',
] as const

export type ValidBetTypes = (typeof ValidBetTypeList)[number]
export const ZValidBetTypes = z.enum(['all', ...ValidBetTypeList])

/**
 * 管理员账号类型
 */
export const AdminTypes = [
	/**
	 * 总商户总管理员
	 */
	'MasterMainAdmin',
	/**
	 * 总商户子管理员
	 */
	'MasterSubAdmin',
	/**
	 * 子商户主管理员
	 */
	'ChildMainAdmin',
	/**
	 * 子商户子管理员
	 */
	'ChildSubAdmin',
] as const
export const ZAdminType = z.enum(['all', 'User', ...AdminTypes])
export type TAdminTypes = (typeof AdminTypes)[number]

export type TAdminPower = {
	[K in TAdminTypes]: number
}

/**
 * 定义管理员权利大小
 */
export const AdminPower: TAdminPower = {
	MasterMainAdmin: 1000,
	MasterSubAdmin: 100,
	ChildMainAdmin: 10,
	ChildSubAdmin: 1,
} as const

/**
 * 可创建的管理员类型
 */
export const CanCreateAdminTypes = [
	/**
	 * 总商户子管理员
	 */
	'MasterSubAdmin',
	/**
	 * 子商户子管理员
	 */
	'ChildSubAdmin',
] as const
export const ZCanCreateAdminType = z.enum(['all', ...CanCreateAdminTypes])

/**
 * 后台类型
 */
export const BackEndAdminTypes = [
	/**
	 * 总后台
	 */
	'Master',
	/**
	 * 商户后台
	 */
	'Child',
] as const
export const ZBackEndAdminTypes = z.enum(['all', ...BackEndAdminTypes])

/**
 * 时区列表
 */
export const TimeZoneCodeEnum = [
	'Etc/GMT+12',
	'Etc/GMT+11',
	'Etc/GMT+10',
	'Etc/GMT+9',
	'Etc/GMT+8',
	'Etc/GMT+7',
	'Etc/GMT+6',
	'Etc/GMT+5',
	'Etc/GMT+4',
	'Etc/GMT+3',
	'Etc/GMT+2',
	'Etc/GMT+1',
	'Etc/GMT',
	'Etc/GMT-1',
	'Etc/GMT-2',
	'Etc/GMT-3',
	'Etc/GMT-4',
	'Etc/GMT-5',
	// 印度
	'Asia/Kolkata',
	// 印尼
	'Asia/Jakarta',
	// 越南
	'Asia/Ho_Chi_Minh',
	// 美国华盛顿
	'America/New_York',
	// 泰国
	'Asia/Bangkok',
	// 美国华盛顿太平洋城
	'America/Los_Angeles',
	//费尔南多·迪诺罗尼亚群岛
	'America/Noronha',
	'Etc/GMT-6',
	'Etc/GMT-7',
	'Etc/GMT-8',
	'Etc/GMT-9',
	'Etc/GMT-10',
	'Etc/GMT-11',
	'Etc/GMT-12',
	'Etc/GMT-13',
	'Etc/GMT-14',
] as const

export type TimeZoneCodeType = (typeof TimeZoneCodeEnum)[number]

export const UserInfoNeedTimeZoneCode: TimeZoneCodeType[] = ['Etc/GMT', 'Etc/GMT+3', 'Etc/GMT-8', 'Asia/Kolkata', 'Asia/Jakarta', 'Asia/Ho_Chi_Minh', 'America/New_York', 'Asia/Bangkok',
	'America/Los_Angeles', 'America/Noronha'
]


/*
 * 游戏记录 状态
 */
export const GameRecordStatusList = [
	/** 未结算 */
	'UNSETTLED',
	/** 已结算 */
	'SETTLED',
	/** 系统撤销 */
	'SYSTEMCANCEL',
	/** 玩家撤销 */
	'MANUALLYCANCEL',
] as const
export type GameRecordStatus = (typeof GameRecordStatusList)[number]
export const ZGameRecordStatus = z.enum(['ALL', ...GameRecordStatusList])

/** 角色类型 */
export const ROLETYPE = ['tenant', 'admin', 'tenantSub'] as const
export const ZROLETYPE = z.enum(['all', ...ROLETYPE])

/**
 * 资金帐变类型映射表
 */
export const AssetChangeTypeMap = {
	/** 充值 */
	recharge: [
		/** 人工 */
		'manual',
		/** 三方 */
		'external',
		/** 线下转账 */
		'offlineTransfer',
		/** 三方人工补单 */
		'externalManual',
	] as const,
	/** 提现 */
	withdraw: [
		/** 人工 */
		'manual',
		/** 申请 */
		'apply',
		/** 完成 */
		'complete',
		/** 拒绝 */
		'reject',
		/** 罚没 */
		'confiscation',
	] as const,
	/** 活动优惠 */
	activity: [
		/** 人工存入 */
		'manualRecharge',
		/** 三方充值 支付那边渠道本身的赠送*/
		'externalRecharge',
		/** 线下转账 支付那边渠道本身的赠送*/
		'offlineTransfer',
		/** 充值活动 */
		'rechargeReward',
		/** 人工赠送 */
		'manualGift',
		/** 渠道送金 */
		'channelGift',
		/** 首充活动 */
		'firstRecharge',
		/** 累充活动 */
		'sumRecharge',
		/** 单笔充值活动 */
		'singleRecharge',
		/** vip */
		'vip',
		/** 活动签到奖励 */
		'signIn',
		/** 每日救援金 */
		'dailyAssistance',
		/** 每周救援金 */
		'weeklyAssistance',
		/** 实时返水 */
		'rebate',
		/** 红包雨 */
		'redPacket',
		/** 代理 */
		'agency',
		/** 自定义 */
		'custom',
		/** 幸运转盘 */
		'luckyWheel',
		/** 兑换码 */
		'redeemCode',
		/** 助力领现金 */
		'assistanceCash',
		/** 安装pwa/apk赠送 */
		'installGift',
		/**会员答谢 */
		'memberReward',
		/** 佣金排行榜奖励 */
		'commissionRankReward',
		/**注册奖励 */
		'registerReward',
		/**神秘彩金 */
		'mysteryReward',
	] as const,
	/** 游戏 */
	game: [
		/** 投注 */
		'bet',
		/** 结算 */
		'settle',
		/** 撤单 */
		'cancel',
		/** 回退 */
		'rollback',
	] as const,
	/** 平台钱包 */
	platform: [
		/** 转入 */
		'in',
		/** 转出 */
		'out',
	] as const,
	/** 保险柜 */
	safebox: [
		/** 转入 */
		'in',
		/** 转出 */
		'out',
	] as const,
	/** 佣金 */
	commission: [
		/** 结算 */
		'settle',
		/** 领取 */
		'receive',
	] as const,
} as const

export type TAssetChangeTypeMap = typeof AssetChangeTypeMap

export type AssetChangeMainType = keyof typeof AssetChangeTypeMap
export const AssetChangeMainTypes = typeObjectKeys(AssetChangeTypeMap)

export type AssetChangeSubType = {
	[K in keyof TAssetChangeTypeMap]: `${K}:${TAssetChangeTypeMap[K][number]}`
}[keyof TAssetChangeTypeMap]

export const AssetChangeSubTypes: [AssetChangeSubType, ...AssetChangeSubType[]] = typeObjectKeys(
	AssetChangeTypeMap,
).flatMap((item: AssetChangeMainType) => AssetChangeTypeMap[item].map((subItem) => `${item}:${subItem}`)) as [
		AssetChangeSubType,
		...AssetChangeSubType[],
	]

/**
 * 彩金类型
 */
export const RewardTypeEnum = [
	// "manual_deposit", // 手动存款
	'manual_reward', // 人工彩金
	'register_give', // 注册赠送
	'recharge_give', // 充值赠送
	'test_amount', // 测试金额
] as const
export type RewardType = (typeof RewardTypeEnum)[number]
export const ZRewardType = z.enum(['all', ...RewardTypeEnum])

/**
 * 操作类型
 */
export const OperationTypeEnum = [
	'manual_deposit', // 手动存款
	'manual_withdrawal', // 手动提现
	'manual_reward', // 手动奖励
] as const
export type OperationType = (typeof OperationTypeEnum)[number]
export const ZOperationType = z.enum(['all', ...OperationTypeEnum])

/** 
 * 领取方式
 */
export const ReceiveTypeEnum = [
	/** 手动 */
	'manual',
	/** 自动 */
	'auto',
] as const
export type ReceiveType = (typeof ReceiveTypeEnum)[number]
export const ZReceiveType = z.enum(['all', ...ReceiveTypeEnum])

/**
 * 领取状态
 */
export const ReceiveStatusEnum = [
	/** 未领取 */
	'notReceived',
	/** 已领取 */
	'received',
] as const
export type ReceiveStatus = (typeof ReceiveStatusEnum)[number]
export const ZReceiveStatus = z.enum(['all', ...ReceiveStatusEnum])

/**
 * 用户账号状态
 */
export const UserStatusEnum = ['normal', 'freeze'] as const
export type UserStatus = (typeof UserStatusEnum)[number]
export const ZUserStatus = z.enum(['all', ...UserStatusEnum])

/**
 * 用户账号类型
 */
export const UserTypeEnum = ['normal', 'test', 'demo'] as const
export type UserType = (typeof UserTypeEnum)[number]
export const ZUserType = z.enum(['all', ...UserTypeEnum])

/**
 * 查询是否在线
 */
export const IsOnlineEnum = ['false', 'true'] as const
export type IsOnline = (typeof IsOnlineEnum)[number]
export const ZIsOnline = z.enum(['all', ...IsOnlineEnum])

/**
 * 用户分页数据查询类型
 */
export const UserPageQueryTypeEnum = [
	'userId', // 用户ID
	'account', // 账号
	'registerIp', // 注册IP
	'phone', // 手机号
	'email', // 邮箱
	'parentId', // 上级ID
	'topId', // 顶级ID
	'recommendedId', // 推荐人ID
	'CPF', // CPF
	'withdrawPhone' // 提现手机号
] as const
export const ZUserPageQueryType = z.enum(['all', ...UserPageQueryTypeEnum])

/**
 * 用户分页数据金币类型
 */
export const UserPageCoinType = [
	// "all", // 全部
	'dayPay', // 每日充值
	'dayBet', // 每日投注
	'dayWinsLoses', // 每日输赢
	'dayReward', // 每日奖励
	'dayWithdrawals', // 每日提现
	'historicalPay', // 历史充值
	'historicalWithdrawals', // 历史提现
	'historicalBet', // 历史投注
	'historyWinsLoses', // 历史输赢
	'historicalReward', // 历史奖励
] as const
export const ZUserPageCoinType = z.enum(['all', ...UserPageCoinType])

/**
 * 用户详情返回数据
 */
export interface UserDetail {
	id: number
	type: 'normal' | 'test' | null
	status: 'normal' | 'freeze'
	isOnline: boolean
	channelId: number
	channelName: string | null
	level: {
		id: number
		name: string
		tenantId: number
		type: 'DEFAULT' | 'FORMAL' | 'CUSTOMIZE'
		createTime: Date
		updateTime: Date
		lastOperator: string | null
		sort: number | null
		remark: string | null
		recharge: number | null
		bet: number | null
		directRecharge: number | null
		direct: number | null
		withdrawalLimit: number | null
		maxDayWithdrawal: number | null
	} | null
	realName: string | null
	phoneNumber: string | null
	email: string | null
	parentId: number | null
	remark: string | null
	noBetting: boolean
	userName: string
	tenantId: number
	registerIp: string
	lastLoginIp: string
	registerTime: Date
	lastLoginTime: Date
	lastLoginDevice: string
	registerDevice: string
	registerDeviceModel: string
	loginDeviceModel: string
	tenantName: string
	regionName: string | null
	regionId: number | null
	regionCurrency: string | null
	balance: number
	yuebaoBalance: number
	safeBalance: number
	freezeAmount: number
	commission: number
	historicalPay: number
	historyWithdrawals: number
	historicalBetting: number
	historicalReward: number
	historicalCommissions: number
	historyWinsLoses: number
	historicalValidBetting: number
	recharge: number
	withdrawals: number
	betting: number
	winsLose: number
	commissions: number
	winMax: number
	reward: number
	validBetting: number
	bankName: string | null
	parentName: string | null
	registerIpCount: number
	LoginIpCount: number
	realNameCount: number
	sameParentCount: number
	goalPoint: number
	strength: number
	curPoint: number
	needFlow: number
	rechargeMultiple: number
	rewardMultiple: number
	vipLevel: number
}

/**
 * 邮件类型
 */
export const MailOperationTypeEnum = ['users', 'level'] as const
export type MailOperationType = (typeof MailOperationTypeEnum)[number]
export const ZMailOperationTypeEnum = z.enum(['all', ...MailOperationTypeEnum])

export const MailStatusEnum = ['sending', 'terminate', 'success'] as const
export type MailStatus = (typeof MailStatusEnum)[number]
export const ZMailStatusEnum = z.enum(['all', ...MailStatusEnum])

export const MailisOnlineEnum = ['all', 'online', 'offline'] as const
export type MailisOnline = (typeof MailisOnlineEnum)[number]
export const ZMailisOnlineEnum = z.enum(['all', ...MailisOnlineEnum])

// =================代理相关================================
/**
 * 代理模式
 * @unlimitedLevel 无限级差
 * @multipleLevel 多级分销
 */
export const AgencyMode = ['unlimitedLevel', 'multipleLevel'] as const
export type TAgencyMode = (typeof AgencyMode)[number]
export const ZAgencyMode = z.enum(['all', ...AgencyMode])
/**
 * 返佣类型
 * @gameType 区分游戏类型
 * @noGameType 不区分游戏类型
 */
export const CommissionType = ['gameType', 'noGameType'] as const
/**
 * 业绩统计类型
 * @validBet 有效投注
 * @recharge 充值
 */
export const AchievementType = ['validBet', 'recharge'] as const
/**
 * 代理状态
 * @normal 正常
 * @settled 佣金已结算
 * @issued 佣金已发放
 */
export const AgencyStatus = ['normal', 'settled', 'issued'] as const
export type TAgencyStatus = (typeof AgencyStatus)[number]
/**
 * 代理关系
 * @direct 直属
 * @team 团队
 */
export const AgencyRelationType = ['direct', 'team'] as const
export type TAgencyRelationType = (typeof AgencyRelationType)[number]
export type TSubLevel = number | TAgencyRelationType
/**
 * 跳转类型
 * @home 首页
 * @registe 注册页
 */
export const JumpType = ['home', 'registe'] as const
/**
 * 默认平台id
 */
export const DefaultPlatformId = 1
/**
 * 代理关系查询类型
 * @sub 下级
 * @top 上级
 */
export const AgencyQueryType = ['sub', 'top'] as const
export type TAgencyQueryType = (typeof AgencyQueryType)[number]
/**
 * 无限级差模式结构
 */
export const UnlimitedLevelConfig = z.array(z.object({
	/** 代理等级 */
	level: z.number(),
	/** 需要流水 */
	needFlow: z.number(),
	/** 游戏类型 */
	gameType: z.enum(GameTypes),
	/** 返佣比例 */
	rat: z.number(),
}))
export type TUnlimitedLevelConfig = z.infer<typeof UnlimitedLevelConfig>
/**
 * 多级分销模式结构
 */
export const MultipleLevelConfig = z.array(z.object({
	/** 代理级别名称 */
	name: z.string(),
	/** 代理等级 */
	level: z.number(),
	/** 成员数量 */
	count: z.number(),
	/** 累计代理线充值 */
	totalLineRecharge: z.number(),
	/** 累计直属充值 */
	totalDirectRecharge: z.number(),
	/** 累计团队充值 */
	totalTeamRecharge: z.number(),
	/** 当日代理线充值 */
	dayLineRecharge: z.number(),
	/** 当日直属充值 */
	dayDirectRecharge: z.number(),
	/** 当日团队充值 */
	dayTeamRecharge: z.number(),
	/** 当日代理线有效投注 */
	dayLineValidBet: z.number(),
	/** 当日直属有效投注 */
	dayDirectValidBet: z.number(),
	/** 当日团队有效投注 */
	dayTeamValidBet: z.number(),
	/** 返佣比例 */
	rats: z.array(z.object({
		/** 游戏类型 */
		gameType: z.enum(GameTypes),
		/** 代理等级 */
		level: z.number(),
		/** 返佣比例 */
		rat: z.number(),
	})),
}))

export type TMultipleLevelConfig = z.infer<typeof MultipleLevelConfig>

/**
 * 代理排行榜奖励类型
 * @day 日榜
 * @week 周榜
 * @month 月榜
 */
export const AgencyRankRewardType = ['day', 'week', 'month'] as const
export type TAgencyRankRewardType = (typeof AgencyRankRewardType)[number]
export const ZAgencyRankRewardType = z.enum(['all', ...AgencyRankRewardType])
// =================================================================================
// 支付相关

/**
 *
 * 平台Code
 */
export const PaymentCodes = [
	/* epay */
	'EPAY',
	/* hkp */
	'HKPPAY',
	/* toppay */
	'TOPPAY',
	/** mockpay */
	'MOCKPAY',
	/** cbpay */
	'CBPAY',
	/** 汉比特 */
	'HBPAY',
	/**GLOBALPAY */
	'GLOBALPAY',
	/**TOPPAYNEW */
	'TOPPAYNEW',
	/**transafePayPayment */
	'TFPAY',
	/** win Pay 支付 */
	'WINPAY',
	/**GLOBALPAY NEW */
	'GLOBALPAYNEW',
	/**富盈国际 */
	'FYPAY',
	/**河马支付 */
	'HEMAPAY',
	/** betcatpay */
	'BETCATPAY',
	/** todaypay */
	'TODAYPAY',
	/** agpay */
	'AGPAY',
	/** mlpay */
	'MLPAY',
	/** 668pay */
	'668PAY',
	/** 55luck */
	'55LUCK',
	/**AAPAY */
	'AAPAY',
	/**GREPAY */
	'GREPAY',
	/**SHPAY */
	'SHPAY',
	/** WTPAY 万腾支付 */
	'WTPAY',
	/** payablepay 派乐支付 */
	'PAYABLEPAY',
	/**GLOBALPAYNEW-VISA */
	'GLOBALPAYNEW-VISA',
	/**SU7PAY  用的hanbit的class*/
	'SU7PAY',
	/**wwwpay */
	'WWWPAY',
	/**bbpay cbpay的二类 */
	'BBPAY',
	/**KPPAY */
	'KPPAY',
	/** dgpay  */
	'DGPAY',
	/** brpay */
	'BRPAY',
	/** BBNPAY */
	'BBNPAY',
	/** WINWINPAY */
	'WINWINPAY',
	/** SITOPAY */
	'SITOPAY',
	/** singlepay */
	'SINGLEPAY',
	/** ppay */
	'PPAY',
] as const

export type PaymentCode = (typeof PaymentCodes)[number]
export const ZPaymentCode = z.enum([...PaymentCodes])

/**
 *
 * 平台类型
 */
export const PaymentTypes = [
	/* 支付 */
	'PAY',
	/* 代付 */
	'WITHDRAW',
] as const

export type PaymentType = (typeof PaymentTypes)[number]
export const ZPaymentType = z.enum([...PaymentTypes])

/**
 *
 * 处理方式
 */
export const ProcessModes = [
	/* 三方支付 */
	'THREE_PARTY_PAYMENT',
	/* 转账 */
	'TRANSFER',
] as const

export type ProcessMode = (typeof ProcessModes)[number]
export const ZProcessMode = z.enum(['all', ...ProcessModes])

/**
 *
 * 处理方式
 */
export const MoneyTypes = [
	/* 固定金额 */
	'FIXED',
	/* 浮动金额 */
	'RANGE',
] as const

export type MoneyType = (typeof MoneyTypes)[number]
export const ZMoneyType = z.enum(['all', ...MoneyTypes])

/**
 *
 * 支付渠道状态
 */
export const PayChannelStatusList = [
	/* 测试 */
	'TEST',
	/* 启用 */
	'ON',
	/* 禁用 */
	'OFF',
	/* 删除 */
	'DELETE',
] as const

export type PayChannelStatus = (typeof PayChannelStatusList)[number]
export const ZPayChannelStatus = z.enum(['all', ...PayChannelStatusList])

/**
 *
 * 代付渠道状态
 */
export const WithdrawChannelStatusList = [
	/* 启用 */
	'ON',
	/* 禁用 */
	'OFF',
	/* 删除 */
	'DELETE',
] as const

export type WithdrawChannelStatus = (typeof WithdrawChannelStatusList)[number]
export const ZWithdrawChannelStatus = z.enum(['all', ...WithdrawChannelStatusList])

/**
 *
 * 支付子类型状态
 */
export const PayTypeStatusList = [
	/* 启用 */
	'ON',
	/* 禁用 */
	'OFF',
] as const

export type PayTypeStatus = (typeof PayTypeStatusList)[number]
export const ZPayTypeStatus = z.enum(['all', ...PayTypeStatusList])

/**
 *
 * 支付子类型标签
 */
export const PayTypeTagList = [
	/* 无 */
	'NOTHING',
	/* 赠送 */
	'GIVE_AWAY',
	/** 推荐 */
	'RECOMMEND',
	/** 优惠 */
	'DISCOUNT',
] as const

export type PayTypeTag = (typeof PayTypeTagList)[number]
export const ZPayTypeTag = z.enum(['all', ...PayTypeTagList])

/**
 *
 * 支付订单状态
 */
export const UPayRecordStatusList = [
	/* 待支付 */
	'BE_PAID',
	/** 已支付 */
	'PAID',
	/** 补单完成 */
	'MANUAL_COMPLETTION',
	/** 取消 */
	'CANCEL',
	/** 已超时 */
	'TIMEOUT',
] as const

export type UPayRecordStatus = (typeof UPayRecordStatusList)[number]
export const ZUPayRecordStatus = z.enum(['all', ...UPayRecordStatusList])

/**
 *
 * 用户金额变动状态
 */
export const UPayRecordChangeAmountStatusList = [
	/** 超出限制 */
	'LIMIT_EXCEEED',
	/* 已到帐 */
	'HAVE_ARRIVED',
	/** 未到帐 */
	'NOT_ARRIVED',
] as const

export type UPayRecordChangeAmountStatus = (typeof UPayRecordChangeAmountStatusList)[number]
export const ZUPayRecordChangeAmountStatus = z.enum(['all', ...UPayRecordChangeAmountStatusList])

/**
 *
 * 转账订单状态
 */
export const UTransferApplyStatusList = [
	/* 待支付 */
	'BE_PAID',
	/* 待审核 */
	'TO-BE-REVIEW',
	/** 审核中 */
	'UNDER-REVIEW',
	/** 已上分 */
	'SUCCESS',
	/** 人工结束 */
	'MANUALLY-END',
	/** 已超时 */
	'TIMEOUT',
	/** 取消 */
	'CANCEL',
] as const

export type UTransferApplyStatus = (typeof UTransferApplyStatusList)[number]
export const ZUTransferApplyStatus = z.enum(['all', ...UTransferApplyStatusList])

/**
 *
 * 提现类型
 */
export const WithdrawalTypeEnum = [
	/* 用户提现 */
	'withdrawal',
	/* 佣金提现 */
	'commission',
] as const

export type WithdrawalType = (typeof WithdrawalTypeEnum)[number]

/**
 *
 * 提现类型
 */
export const WithdrawalStatusEnum = [
	/* 申请,待审核订单 */
	'apply',
	/* 锁定 ，审核中 */
	'lock',
	/* 拒绝 */
	'refuse',
	/* 罚没 */
	'confiscate',
	/* 代付中 */
	'inprog',
	/* 代付失败 */
	'fail',
	/* 代付异常 */
	'error',
	/* 通过 */
	'success',
] as const

export type WithdrawalStatus = (typeof WithdrawalStatusEnum)[number]

export const ZWithdrawalStatus = z.enum(['all', ...WithdrawalStatusEnum])

/**
 * oAuth 三方认证schema
 */
export const OauthStateSchema = z.object({
	/** oauth state */
	state: z.string(),
	/** 商户id */
	tenantId: z.coerce.number().describe('商户id'),
	/** 渠道id */
	channelId: z.coerce.number().optional().describe('渠道id'),
	/** 重定向地址 */
	redirect: z.string().url().describe('重定向地址'),
	/** callback地址 */
	callback: z.string().url().optional().describe('callback地址'),
	/**
	 * auth 方式
	 * login: 登陆
	 * bind: 绑定
	 */
	action: z.enum(['login', 'bind']).default('login').describe('auth类型'),
	userId: z.coerce.number().optional().describe('用户id'),
})

export type TOauthStateSchema = z.infer<typeof OauthStateSchema>

/**
 * 设备类型
 */
export const DeviceTypes = [
	/* 安卓 */
	'ANDROID',
	/* ios */
	'IOS',
	/* web */
	'WEB',
] as const
export type DeviceType = (typeof DeviceTypes)[number]
export const ZDeviceType = z.enum(['all', ...DeviceTypes])

/**
 * 支付通道设备类型
 */
export const PaymentDeviceTypes = [
	/* 全部 */
	'ALL',
	...DeviceTypes,
] as const
export type PaymentDeviceType = (typeof PaymentDeviceTypes)[number]
export const ZPaymentDeviceType = z.enum([...PaymentDeviceTypes])

export const AccountLimitEnum = [
	// 不限制
	'NoLimit',
	// 没有充值用户
	'NoRechargeUser',
] as const

export type TAccountLimitEnum = (typeof AccountLimitEnum)[number]

export const FeeTypeEnum = [
	// shang
	'UP',
	// xia
	'DOWN',
] as const

export type TFeeTypeEnum = (typeof FeeTypeEnum)[number]

/**
 * 注册方式
 */
export const RegisterTypes = ['Account', 'Phone', 'Email'] as const
export type TRegisterType = (typeof RegisterTypes)[number]
export const RegisterType = z.enum(RegisterTypes)

/**
 * 注册所需信息
 */
export const RegisterRequireSchema = z.enum(['Null', 'Phone', 'Email'])

/**
 * 第三方登录方式
 */
export const ThirdPartyLoginSchema = z.enum(['google', 'facebook', 'twitter', 'github'])

export type TThirdPartyLoginSchema = z.infer<typeof ThirdPartyLoginSchema>


/**
 * 用户注册类型
 */
export const RegisterTypeMap = {
	/** 账号 */
	account: [
		/** 普通账号 */
		'account',
	] as const,
	/** 电话 */
	phone: [
		/** 电话 */
		'phone',
		/** 申请 */
	] as const,
	/** 邮箱 */
	email: [
		/**email */
		'email',
		/** github */
		'github',
		/** 谷歌*/
		'google',
		/**twitter */
		'twitter',
	] as const,
} as const

export type TRegisterTypeMap = typeof RegisterTypeMap

export type RegisterTypeMainType = keyof typeof RegisterTypeMap
export const RegisterTypeMainTypes = typeObjectKeys(RegisterTypeMap)

export type RegisterTypeSubMap = {
	[K in keyof TRegisterTypeMap]: `${K}:${TRegisterTypeMap[K][number]}`
}[keyof TRegisterTypeMap]

export const RegisterTypeSubMaps: [RegisterTypeSubMap, ...RegisterTypeSubMap[]] = typeObjectKeys(
	RegisterTypeMap,
).flatMap((item: RegisterTypeMainType) => RegisterTypeMap[item].map((subItem) => `${item}:${subItem}`)) as [
		RegisterTypeSubMap,
		...RegisterTypeSubMap[],
	]
export const ZRegisterTypeSubMaps = z.enum(RegisterTypeSubMaps)

export enum RegisterTypeALL {
	/** 账号 */
	Account = 'account:account',
	/** 电话 */
	Phone = 'phone:phone',
	/** 邮箱 */
	Email = 'email:email',
	/** github */
	Github = 'email:github',
	/** 谷歌 */
	Google = 'email:google',
	/** twitter */
	Twitter = 'email:twitter',
}


// =================渠道相关================================
/**
 * 跳转指向
 * home 首页
 * google google
 */
export const JumpToEnum = ['home', 'google'] as const

/**
 * 域名类型
 * app 主域名
 * promotion 推广域名
 */
export const DomainCategoryEnum = ['app', 'promotion'] as const
export type TDomainCategoryEnum = (typeof DomainCategoryEnum)[number]

/**
 * 网络托管商
 */
export const DomainProviderEnum = ['cloudflare'] as const

/**
 * 样式类型
 */
export const StyleTypeEnum = ['style_1', 'style_2'] as const

/**
 * 域名有效性
 * 有效 valid
 * 未生效 notEffective
 * 已失效 invcalid
 */
export const DomainEfficacyEnum = ['valid', 'notEffective', 'invcalid'] as const

/**
 * 跳转域名类型 主域名 自定义
 */
export const JumpDomainTypeEnum = ['main', 'custom'] as const

/**
 * 落地指向 首页 注册页 谷歌 助力活动 活动优惠
 */
export const LandingEnum = ['home', 'register', 'google', 'assistance', 'discount'] as const

/**
 * 留存率统计类型
 */
export const RetentionRateTypeEnum = ['login', 'recharge', 'bet'] as const

export type TRetentionRateTypeEnum = (typeof RetentionRateTypeEnum)[number]
// ========================================================

/**
 * 提现小类code
 */
export const WithdrawTypeSubCodeEnum = ['', 'PHONE', 'EMAIL', 'CPF', 'CNPJ', 'EVP'] as const
export type TWithdrawTypeSubCodeEnum = (typeof WithdrawTypeSubCodeEnum)[number]
export const ZWithdrawTypeSubCodeEnum = z.enum(WithdrawTypeSubCodeEnum)

/**
 * 印度 银行名编码
 */
export const INRBankCodeEnum = [
	/** ASF: AU Small Finance Bank: 澳大利亚小型金融银行 */
	'ASF',
	/** ABP: Aditya Birla Payments Bank: 阿迪亚·比拉支付银行 */
	'ABP',
	/** AP: Airtel Payments Bank: Airtel支付银行 */
	'AP',
	/** ALLA: Allahabad Bank: 安拉哈巴德银行 */
	'ALLA',
	/** ANDH: Andhra Bank: 安得拉银行 */
	'ANDH',
	/** APGV: Andhra Pradesh Grameena Vikas Bank: 安得拉邦Grameena Vikas银行 */
	'APGV',
	/** APG: Andhra Pragathi Grameena Bank: 安得拉Pragathi Grameena银行 */
	'APG',
	/** APR: Arunachal Pradesh Rural Bank: 阿鲁纳恰尔邦农村银行 */
	'APR',
	/** ARYA: Aryavart Bank: Aryavart银行 */
	'ARYA',
	/** AGV: Assam Gramin Vikash Bank: 阿萨姆Gramin Vikash银行 */
	'AGV',
	/** AXIS: Axis Bank: 轴心银行 */
	'AXIS',
	/** BOBAK: BANK OF BAHRAIN AND KUWAIT: 巴林和科威特银行 */
	'BOBAK',
	/** BHARAT: BHARAT BANK: Bharat银行 */
	'BHARAT',
	/** BANDHAN: Bandhan Bank: Bandhan银行 */
	'BANDHAN',
	/** BGV: Bangiya Gramin Vikash Bank: Bangiya Gramin Vikash银行 */
	'BGV',
	/** DBS: Bank DBS Indonesia: DBS印度尼西亚银行 */
	'DBS',
	/** BOB: Bank of Baroda: 巴罗达银行 */
	'BOB',
	/** BOI: Bank of India: 印度银行 */
	'BOI',
	/** BOM: Bank of Maharashtra: 马哈拉施特拉银行 */
	'BOM',
	/** BGG: Baroda Gujarat Gramin Bank: 巴罗达古吉拉特Gramin银行 */
	'BGG',
	/** BRKG: Baroda Rajasthan Kshetriya Gramin Bank: 巴罗达拉贾斯坦Kshetriya Gramin银行 */
	'BRKG',
	/** BUPG: Baroda Uttar Pradesh Gramin Bank: 巴罗达北方邦Gramin银行 */
	'BUPG',
	/** BCCO: Bassein Catholic Co-operative Bank: 巴塞因天主教合作银行 */
	'BCCO',
	/** CSFB: CAPITAL SMALL FINANCE BANK LTD: 资本小型金融银行有限公司 */
	'CSFB',
	/** COSMOS: COSMOS BANK: 宇宙银行 */
	'COSMOS',
	/** CSB: CSB Bank: CSB银行 */
	'CSB',
	/** CANARA: Canara Bank: 卡纳拉银行 */
	'CANARA',
	/** CASY: Catholic Syrian Bank: 天主教叙利亚银行 */
	'CASY',
	/** CBOI: Central Bank of India: 中央银行 */
	'CBOI',
	/** CGG: Chaitanya Godavari Grameena Bank: Chaitanya Godavari Grameena银行 */
	'CGG',
	/** CRG: Chhattisgarh Rajya Gramin Bank: Chhattisgarh Rajya Gramin银行 */
	'CRG',
	/** CITI: Citi bank: 花旗银行 */
	'CITI',
	/** CU: City Union Bank: 城市联盟银行 */
	'CU',
	/** CLA: Coastal Local Area Bank Limited: 海岸地方银行有限公司 */
	'CLA',
	/** CORP: Corporation Bank: 公司银行 */
	'CORP',
	/** DCB: DCB Bank: DCB银行 */
	'DCB',
	/** DBOS: DEVELOPMENT BANK OF SINGAPORE: 新加坡发展银行 */
	'DBOS',
	/** DNLM: DNLM: DNLM */
	'DNLM',
	/** DBG: Dakshin Bihar Gramin Bank: 达克辛比哈尔Gramin银行 */
	'DBG',
	/** DENA: Dena Bank: 德纳银行 */
	'DENA',
	/** DBAG: Deutsche Bank AG: 德意志银行 */
	'DBAG',
	/** DHAN: Dhanlaxmi Bank: Dhanlaxmi银行 */
	'DHAN',
	/** ESAF: ESAF Small Finance Bank: ESAF小型金融银行 */
	'ESAF',
	/** ELD: Ellaquai Dehati Bank: Ellaquai Dehati银行 */
	'ELD',
	/** ESF: Equitas Small Finance Bank: Equitas小型金融银行 */
	'ESF',
	/** EQUITAS: Equitas bank: Equitas银行 */
	'EQUITAS',
	/** EXIM: Exim Bank: 出口银行 */
	'EXIM',
	/** FIP: FINO PAYMENT BANK: FINO支付银行 */
	'FIP',
	/** FEDERAL: Federal Bank: 联邦银行 */
	'FEDERAL',
	/** FSF: Fincare Small Finance Bank: Fincare小型金融银行 */
	'FSF',
	/** GPPJS: GOPINATH PATIL PARSIK JANATA SAHAKARI BANK LTD: GOPINATH PATIL PARSIK JANATA SAHAKARI银行有限公司 */
	'GPPJS',
	/** HDFC: HDFC Bank: HDFC银行 */
	'HDFC',
	/** HSBC: HSBC Bank: 汇丰银行 */
	'HSBC',
	/** HPG: Himachal Pradesh Gramin Bank: 喜马偕尔邦Gramin银行 */
	'HPG',
	/** ICICI: ICICI Bank: ICICI银行 */
	'ICICI',
	/** IDBI: IDBI Bank: IDBI银行 */
	'IDBI',
	/** IDFC: IDFC First Bank: IDFC第一银行 */
	'IDFC',
	/** IPP: India Post Payments Bank: 印度邮政支付银行 */
	'IPP',
	/** IND: Indian Bank: 印度银行 */
	'IND',
	/** INDOV: Indian Overseas Bank: 印度海外银行 */
	'INDOV',
	/** INDUSIND: IndusInd Bank: IndusInd银行 */
	'INDUSIND',
	/** JKG: J&K Grameen Bank: J&K Grameen银行 */
	'JKG',
	/** JANATA: JANATA SAHAKARI BANK LTD PUNE: JANATA SAHAKARI银行有限公司浦那 */
	'JANATA',
	/** JAK: Jammu and Kashmir Bank: 查谟和克什米尔银行 */
	'JAK',
	/** JSF: Jana Small Finance Bank: Jana小型金融银行 */
	'JSF',
	/** JRG: Jharkhand Rajya Gramin Bank: 贾坎德邦Rajya Gramin银行 */
	'JRG',
	/** JIO: Jio Payments Bank: Jio支付银行 */
	'JIO',
	/** KARNATAKA: Karnataka Bank: 卡纳塔克邦银行 */
	'KARNATAKA',
	/** KG: Karnataka Gramin Bank: 卡纳塔克邦Gramin银行 */
	'KG',
	/** KVG: Karnataka Vikas Grameena Bank: 卡纳塔克邦Vikas Grameena银行 */
	'KVG',
	/** KV: Karur Vysya Bank: 卡鲁尔Vysya银行 */
	'KV',
	/** KGSG: Kashi Gomti Samyut Gramin Bank: Kashi Gomti Samyut Gramin银行 */
	'KGSG',
	/** KEG: Kerala Gramin Bank: 喀拉拉邦Gramin银行 */
	'KEG',
	/** KM: Kotak Mahindra Bank: Kotak Mahindra银行 */
	'KM',
	/** LV: Lakshmi Vilas Bank: Lakshmi Vilas银行 */
	'LV',
	/** LIND: Lndus Ind Bank: Lndus Ind银行 */
	'LIND',
	/** MBG: MADHYA BIHAR GRAMIN BANK: 马德亚比哈尔Gramin银行 */
	'MBG',
	/** MR: MEGHALAYA RURAL BANK: 梅加拉亚农村银行 */
	'MR',
	/** MPG: Madhya Pradesh Gramin Bank: 马德亚邦Gramin银行 */
	'MPG',
	/** MADG: Madhyanchal Gramin Bank: Madhyanchal Gramin银行 */
	'MADG',
	/** MAHG: Maharashtra Gramin Bank: 马哈拉施特拉Gramin银行 */
	'MAHG',
	/** MANIPURR: Manipur Rural Bank: 曼尼普尔农村银行 */
	'MANIPURR',
	/** MIZORAMR: Mizoram Rural Bank: 米佐拉姆农村银行 */
	'MIZORAMR',
	/** NHB: NHB: NHB */
	'NHB',
	/** NSDL: NSDL Payments Bank: NSDL支付银行 */
	'NSDL',
	/** NAGALANDR: Nagaland Rural Bank: 那加兰农村银行 */
	'NAGALANDR',
	/** NAINITAL: Nainital Bank: 奈尼塔尔银行 */
	'NAINITAL',
	/** NBARD: National Bankfor Agricultureand Rural Development: 国家农业和农村发展银行 */
	'NBARD',
	/** NESF: North East Small Finance Bank: 东北小型金融银行 */
	'NESF',
	/** OG: Odisha Gramya Bank: 奥里萨Grameya银行 */
	'OG',
	/** OBOC: Oriental Bank of Commerce: 东方商业银行 */
	'OBOC',
	/** PAYTM: PAYTM PAYMENTS BANK: PAYTM支付银行 */
	'PAYTM',
	/** PUNJAB: PUNJAB GRAMIN BANK: 旁遮普Gramin银行 */
	'PUNJAB',
	/** PBG: Paschim Banga Gramin Bank: Paschim Banga Gramin银行 */
	'PBG',
	/** PKG: Pragathi Krishna Gramin Bank: Pragathi Krishna Gramin银行 */
	'PKG',
	/** PKGB: Pragati Krushna Gramina Bank: Pragati Krushna Gramina银行 */
	'PKGB',
	/** PUPG: Prathama UP Gramin Bank: Prathama UP Gramin银行 */
	'PUPG',
	/** PBGB: Puduvai Bharathiar Grama Bank: Puduvai Bharathiar Grama银行 */
	'PBGB',
	/** PUNNA: Punjab National Bank: 旁遮普国家银行 */
	'PUNNA',
	/** PAS: Punjab and Sind Bank: 旁遮普和信德银行 */
	'PAS',
	/** PURVAN: Purvanchal Bank: Purvanchal银行 */
	'PURVAN',
	/** RBL: RBL BANK LTD: RBL银行有限公司 */
	'RBL',
	/** RBOS: ROYAL BANK OF SCOTLAND: 苏格兰皇家银行 */
	'RBOS',
	/** RMG: Rajasthan Marudhara Gramin Bank: 拉贾斯坦Marudhara Gramin银行 */
	'RMG',
	/** RBOI: Reserve Bank of India: 印度储备银行 */
	'RBOI',
	/** SCBTP: STANDARD CHARTERED BANK THAI PCL: 泰国标准渣打银行 */
	'SCBTP',
	/** SCBL: SVC COOPERATIVE BANK LTD: SVC合作银行有限公司 */
	'SCBL',
	/** SG: Saptagiri Grameena Bank: Saptagiri Grameena银行 */
	'SG',
	/** SHG: Sarva Haryana Gramin Bank: Sarva Haryana Gramin银行 */
	'SHG',
	/** SGB: Saurashtra Gramin Bank: Saurashtra Gramin银行 */
	'SGB',
	/** SIDBI: Small Industries Development Bankof India: 小型工业发展银行 */
	'SIDBI',
	/** SIB: South Indian Bank: 南印度银行 */
	'SIB',
	/** SCB: Standard Chartered Bank: 标准渣打银行 */
	'SCB',
	/** SBOH: State Bank of Hyderabad: 海得拉巴国家银行 */
	'SBOH',
	/** SBOI: State Bank of India: 印度国家银行 */
	'SBOI',
	/** SLA: Subhadra Local Area Bank Ltd: Subhadra地方银行有限公司 */
	'SLA',
	/** SSS: Suco Souharda Sahakari Bank: Suco Souharda Sahakari银行 */
	'SSS',
	/** SSFB: Suryoday Small Finance Bank Ltd: Suryoday小型金融银行有限公司 */
	'SSFB',
	/** SYNDICATE: Syndicate Bank: 辛迪加银行 */
	'SYNDICATE',
	/** TNG: Tamil Nadu Grama Bank: 泰米尔纳德邦Grama银行 */
	'TNG',
	/** TMB: Tamilnad Mercantile Bank Limited: 泰米尔纳德商业银行有限公司 */
	'TMB',
	/** TG: Tripura Gramin Bank: 特里普拉Gramin银行 */
	'TG',
	/** UCO: UCO Bank: UCO银行 */
	'UCO',
	/** UBG: UTTAR BIHAR GRAMIN BANK: 北比哈尔Gramin银行 */
	'UBG',
	/** USF: Ujjivan Small Finance Bank: Ujjivan小型金融银行 */
	'USF',
	/** UKO: Uko Bank: Uko银行 */
	'UKO',
	/** UBOI: Union Bank of India: 联合银行 */
	'UBOI',
	/** UNITEDBOI: United Bank of India: 印度联合银行 */
	'UNITEDBOI',
	/** UG: Utkal Grameen Bank: 乌塔尔Grameen银行 */
	'UG',
	/** UTKSF: Utkarsh Small Finance Bank: Utkarsh小型金融银行 */
	'UTKSF',
	/** UBKG: Uttar Banga Kshetriya Gramin Bank: 北孟加拉Kshetriya Gramin银行 */
	'UBKG',
	/** UTTG: Uttarakhand Gramin Bank: 北阿坎德Gramin银行 */
	'UTTG',
	/** VANG: Vananchal Gramin Bank: Vananchal Gramin银行 */
	'VANG',
	/** VKG: Vidharbha Konkan Gramin Bank: Vidharbha Konkan Gramin银行 */
	'VKG',
	/** VIJAYA: Vijaya Bank: Vijaya银行 */
	'VIJAYA',
	/** YES: Yes Bank: Yes银行 */
	'YES',
	/** ICICIC: ICICI corporate Bank: ICICI企业银行 */
	'ICICIC',
	/** SARASWAT: Saraswat Bank: Saraswat银行 */
	'SARASWAT',
	/** TELG: Telangana grameena Bank: 特伦甘纳Grameena银行 */
	'TELG',
] as const

export type TINRBankCodeEnum = (typeof INRBankCodeEnum)[number]
export const ZINRBankCodeEnum = z.enum(INRBankCodeEnum)


/**
 * 菲律宾银行code
 */
export const PHBankCodeEnum = [
	/** GCASH: GCASH钱包 */
	'GCASH',
	/** AUB: Asia United Bank */
	'AUB',
	/** UnionBankEON: UnionBank EON */
	'UnionBankEON',
	/** Starpay: Starpay */
	'Starpay',
	/** EB: Eastwest Bank */
	'EB',
	/** ESB: Equicom Savings Bank */
	'ESB',
	/** MB: Malayan Bank */
	'MB',
	/** ERB: EastWest Rural Bank */
	'ERB',
	/** PB: Producers Bank */
	'PB',
	/** PBC: Philippine Bank of Communications */
	'PBC',
	/** PBB: Philippine Business Bank */
	'PBB',
	/** PNB: Philippine National Bank */
	'PNB',
	/** PSB: Philippine Savings Bank */
	'PSB',
	/** PTC: Philippine Trust Company */
	'PTC',
	/** PVB: Philippine Veterans Bank */
	'PVB',
	/** RBG: Rural Bank of Guinobatan, Inc. */
	'RBG',
	/** RCBC: Rizal Commercial Banking Corporation */
	'RCBC',
	/** RB: Robinsons Bank */
	'RB',
	/** SBC: Security Bank Corporation */
	'SBC',
	/** SBA: Sterling Bank Of Asia */
	'SBA',
	/** SSB: Sun Savings Bank */
	'SSB',
	/** UCPBSAVINGSBANK: UCPB SAVINGS BANK */
	'UCPBSAVINGSBANK',
	/** QCDBI: Queen City Development Bank, Inc. */
	'QCDBI',
	/** UCPB: United Coconut Planters Bank */
	'UCPB',
	/** WDBI: Wealth Development Bank, Inc. */
	'WDBI',
	/** YSBI: Yuanta Savings Bank, Inc. */
	'YSBI',
	/** GrabPay: GrabPay Philippines */
	'GrabPay',
	/** BDOUI: Banco De Oro Unibank, Inc. */
	'BDOUI',
	/** BMI: Bangko Mabuhay (A Rural Bank), Inc. */
	'BMI',
	/** BOC: Bank Of Commerce */
	'BOC',
	/** CTBC: CTBC Bank (Philippines), Inc. */
	'CTBC',
	/** Chinabank: Chinabank */
	'Chinabank',
	/** CBS: Chinabank Savings */
	'CBS',
	/** CBC: Chinatrust Banking Corp */
	'CBC',
	/** ALLBANK: ALLBANK (A Thrift Bank), Inc. */
	'ALLBANK',
	/** BNBI: BDO Network Bank, Inc. */
	'BNBI',
	/** BRBI: Binangonan Rural Bank Inc */
	'BRBI',
	/** Camalig: Camalig Bank */
	'Camalig',
	/** DBI: Dungganun Bank, Inc. */
	'DBI',
	/** GlobeGcash: Globe Gcash */
	'GlobeGcash',
	/** CLRBI: Cebuana Lhuillier Rural Bank, Inc. */
	'CLRBI',
	/** ISLABANK: ISLA Bank (A Thrift Bank), Inc. */
	'ISLABANK',
	/** LOTP: Landbank of the Philippines */
	'LOTP',
	/** MPI: Maybank Philippines, Inc. */
	'MPI',
	/** MBATC: Metropolitan Bank and Trust Co */
	'MBATC',
	/** Omnipay: Omnipay */
	'Omnipay',
	/** PRBI: Partner Rural Bank (Cotabato), Inc. */
	'PRBI',
	/** PPI: Paymaya Philippines, Inc. */
	'PPI',
	/** AlliedBankingCorp: Allied Banking Corp */
	'AlliedBankingCorp',
	/** ING: ING Bank N.V. */
	'ING',
	/** BDBIASB: BPI Direct Banko, Inc., A Savings Bank */
	'BDBIASB',
	/** CSB: Citystate Savings Bank Inc. */
	'CSB',
	/** BPI: Bank Of The Philippine Islands */
	'BPI',
] as const

export type TPHBankCodeEnum = (typeof PHBankCodeEnum)[number]
export const ZPHBankCodeEnum = z.enum(PHBankCodeEnum)


/**
 *
 * 平台提现大类codecode
 */
export const PayWithdrawTypeCodeEnum = [
	'',
	/* PIX */
	'PIX',
	/**银行卡 */
	'BANK',
	/**UPI */
	'UPI',
	/**网络钱包 */
	'WALLET',
] as const

export type TPayWithdrawTypeCodeEnum = (typeof PayWithdrawTypeCodeEnum)[number]

export const OTPBindTypeArray = [
	/** 绑定邮箱 */
	'bind_email',
	/** 绑定手机 */
	'bind_phone',
] as const

/**
 * OTP 类型枚举
 */
export const OTPTypesArray = [
	/** 注册 */
	'signup',
	/** 忘记密码 */
	'forgot_password',
	/** 修改交易密码 */
	'change_asset_password',
	/** 修改密码 */
	'change_password',
	...OTPBindTypeArray,
] as const
export const OTPTypes = z.enum(OTPTypesArray).describe('验证码类型')
export type TOTPTypes = z.infer<typeof OTPTypes>

/**
 * 基础identifier 类型枚举
 */
export const BaseIdentifierTypesArray = [
	/** 交易密码 */
	'phone',
	/** 邮箱 */
	'email',
] as const

export const BaseIdentifierTypes = z.enum(BaseIdentifierTypesArray).describe('标识ID类型')
/**
 * identifier 类型枚举
 */
export const IdentifierTypesArray = [
	/** 密码 */
	'password',
	...BaseIdentifierTypesArray,
] as const
export const IdentifierTypes = z.enum(IdentifierTypesArray).describe('标识ID类型')
export type TIdentifierTypes = z.infer<typeof IdentifierTypes>

/**
 * 提现手续费类型
 */
export const WithdrawalFeeTypeEnum = [
	/* 比例 */
	'ratio',
	/* 固额 */
	'fixed',
] as const

export type TWithdrawalFeeTypeEnum = (typeof WithdrawalFeeTypeEnum)[number]
export const ZTWithdrawalFeeTypeEnum = z.enum(['all', ...WithdrawalFeeTypeEnum])
/**
 * 提现手续费结构体定义
 */
export interface WithdrawalFee {
	min: number
	max: number
	type: TWithdrawalFeeTypeEnum
	value: number
}

/**
 * 打码日志类型
 */
export const FlowLogTypeEnum = [
	/* 充值 */
	'recharge',
	/* 奖励 */
	'reward',
] as const

export type TFlowLogTypeEnum = (typeof FlowLogTypeEnum)[number]

// =================================================================================
// 层级相关

/**
 * 层级类型
 */
export const UserLevelTypes = [
	/* 默认 */
	'DEFAULT',
	/* 正式 */
	'FORMAL',
	/* 自定义 */
	'CUSTOMIZE',
] as const

export type TUserLevelType = (typeof UserLevelTypes)[number]
export const ZUserLevelTypeEnum = z.enum(['all', ...UserLevelTypes])

/**
 *用户每日数据- 用户属性
 */
export const UserAttrsEnum = [
	/* 代理 */
	'agent',
	/* 非代理 */
	'notAgent',
] as const

export type TUserAttrsEnum = (typeof UserAttrsEnum)[number]
export const ZUserAttrsEnum = z.enum(['all', ...UserAttrsEnum])

/**
 *用户每日数据- 查询数据类型
 */
export const UserDayQueryTypeEnum = [
	/* 提现 */
	'recharge',
	/* 提现 */
	'withdrawal',
	/* 输赢 */
	'winsLose',
	/* 充提差 */
	'rechargeWithdrawal',
	/* 有效投注 */
	'validBetting',
	/* 局数 */
	'gameRounds',
	/* 优惠 */
	'reward',
	/* 结算佣金 */
	'settle',
	/**游戏中奖倍数 */
	'gameRewardMultiple',
] as const

export type TUserDayQueryTypeEnum = (typeof UserDayQueryTypeEnum)[number]
export const ZUserDayQueryTypeEnum = z.enum(['all', ...UserDayQueryTypeEnum])

// =================================================================================
// 活动相关

/*
 * vipLevel 领取规则
 */
export const VipReceiveRules = [
	/** 跨天，跨周，跨月时，未领取奖励邮件下发 */
	'RESERVE',
	/** 跨天，跨周，跨月时，未领取奖励系统回收作废 */
	'ABOLISHMENT',
] as const

export type VipReceiveRule = (typeof VipReceiveRules)[number]

export const VipReceiveTimeTypes = [
	/** 每天 */
	'DAILY',
	/** 每周 */
	'WEEKLY',
	/** 每月 */
	'MONTHLY',
] as const

/*
 * vipLevel 领取规则
 */
export const VipReceiveTypes = [
	/** 晋级 */
	'PROMOTION',
	...VipReceiveTimeTypes,
] as const

export type VipReceiveType = (typeof VipReceiveTypes)[number]
export const ZVipReceiveType = z.enum(VipReceiveTypes)

/*
 * vip奖励 领取状态
 */
export const VipReceiveStatusList = [
	/** 未领取 */
	'UNRECEIVED',
	/** 已领取 */
	'RECEIVED',
	/** 作废 */
	'INVALID',
] as const

export type VipReceiveStatus = (typeof VipReceiveStatusList)[number]

/*
 * 兑换码类型
 */
export const RedeemCodeType = [
	/** 通用 */
	'GENERIC',
	/** 唯一 */
	'UNIQUE',
] as const

export type TRedeemCodeType = (typeof RedeemCodeType)[number]

/*
 * 兑换码金额类型
 */
export const RedeemCodeAmountType = [
	/** 充值 */
	'RECHARGE',
	/** 盈亏 */
	'PROFIT',
	/**无限制 */
	'UNLIMITED',
	/**有效投注 */
	'BET',
] as const

export type TRedeemCodeAmountType = (typeof RedeemCodeAmountType)[number]

/*
 * 兑换码奖励类型
 */
export const RedeemCodeAwardType = [
	/** 固定金额 */
	'FIXED_AMOUNT',
	/** 随机金额 */
	'RANDOM_AMOUNT',
	/**固定比例 */
	'FIXED_RATIO',
	/**随机比例 */
	'RANDOM_RATIO',
] as const

export type TRedeemCodeAwardType = (typeof RedeemCodeAwardType)[number]

/*
 * 兑换码时间范围类型
 */
export const RedeemCodeTimeRangeType = [
	/** 历史 */
	'HISTORY',
	/**当日 */
	'TODAY',
	/**本周 */
	'WEEK',
	/**本月 */
	'MONTH',
	/**昨日 */
	'YESTERDAY',
	/**上周 */
	'LAST_WEEK',
	/**上月 */
	'LAST_MONTH',
	/**近七天 */
	'RECENT_SEVEN_DAYS',
	/**近三十天 */
	'RECENT_THIRTY_DAYS',
] as const

export type TRedeemCodeTimeRangeType = (typeof RedeemCodeTimeRangeType)[number]

/**
 *
 * 兑换码状态
 */
export const RedeemCodeSwitch = [
	/* 启用 */
	'ON',
	/* 禁用 */
	'OFF',
] as const

export type TRedeemCodeSwitch = (typeof RedeemCodeSwitch)[number]

/**
 *
 *
 */
export const RegionLimitType = [
	/* ALLOW_ACCESS 允许访问 */
	'ALLOW_ACCESS',
	/* 禁止访问 */
	'DENY_ACCESS',
] as const

export type TRegionLimitType = (typeof RegionLimitType)[number]

/**
 *
 * 兑换码状态
 */
export const RedeemCodeStatus = [
	/* 已使用 */
	'USED',
	/* 未使用 */
	'UNUSED',
] as const

export type TRedeemCodeStatus = (typeof RedeemCodeStatus)[number]

/**流水类型 */
export const FlowType = [
	/** 充值 */
	'RECHARGE',
	/** 活动 */
	'ACTIVITY',
	/** 系统 */
	'SYSTEM',
] as const
export type TFlowType = (typeof FlowType)[number]
export const ZFlowType = z.enum(FlowType)
/**
 * 资金帐变类型映射表
 */
export const BlackListLimitTypeMap = {
	/** 用户 */
	USERS: [
		/** 登录 */
		'LOGIN',
	] as const,
	/** ip */
	IP: [
		/** 登录 */
		'LOGIN',
		/** 注册 */
		'REGISTER',
		/**登录和注册 */
		'LOGIN_REGISTER',
	] as const,
	/** 设备 */
	DEVICE: [
		/** 登录 */
		'LOGIN',
		/** 注册 */
		'REGISTER',
		/**登录和注册 */
		'LOGIN_REGISTER',
	] as const,
	/** 充值 */
	RECHARGE: [
		/** 线上充值 */
		'RECHARGE',
		/** 转账充值 */
		'TRANSFER',
		/** 转账和线上充值 */
		'TRANSFER_RECHARGE',
	] as const,
	/** 提现 */
	WITHDRAW: [
		/** 提现 */
		'WITHDRAW',
	] as const,
} as const

type TBlackListLimitTypeMap = typeof BlackListLimitTypeMap

export type BlackListLimitType = keyof typeof BlackListLimitTypeMap
export const BlackListLimitTypes = typeObjectKeys(BlackListLimitTypeMap)

export type BlackListLimitSubType = {
	[K in keyof TBlackListLimitTypeMap]: `${K}:${TBlackListLimitTypeMap[K][number]}`
}[keyof TBlackListLimitTypeMap]

export const BlackListLimitSubTypes: [BlackListLimitSubType, ...BlackListLimitSubType[]] = typeObjectKeys(
	BlackListLimitTypeMap,
).flatMap((item: BlackListLimitType) => BlackListLimitTypeMap[item].map((subItem) => `${item}:${subItem}`)) as [
		BlackListLimitSubType,
		...BlackListLimitSubType[],
	]

export const ServiceTypeEnum = ['Telegram', 'Whatsapp', 'Skype', 'Facebook', 'Instagram', 'Line'] as const
export type TServiceTypeEnum = (typeof ServiceTypeEnum)[number]

export const PointTypeEnum = ['Normal', 'Facebook', 'TikTok', 'Kwai', 'ShelfPackage', 'APK', 'TikTokAPI', 'GTM', 'FacebookAPI', 'KwaiAPI', 'AFAPI',
	'MgSkyAds',
] as const
export type TPointTypeEnum = (typeof PointTypeEnum)[number]

export const InstallSendMoneyTypeEnum = ['OFF', 'recharge', 'login'] as const
export type TInstallSendMoneyTypeEnum = (typeof InstallSendMoneyTypeEnum)[number]

/**
 * 弹窗内容类型
 */
export const PopupContentTypeEnum = [
	/** 常规升级 */
	'NORMAL',
	/** 赠送彩金升级 */
	'REWARD',
] as const
export type TPopupContentTypeEnum = (typeof PopupContentTypeEnum)[number]

/**
 * ios弹窗内容类型
 */
export const IosPopupContentTypeEnum = [
	/** 保存桌面 */
	'DESK',
	/** 苹果商店 */
	'APPSTORE',
] as const
export type TIosPopupContentTypeEnum = (typeof IosPopupContentTypeEnum)[number]

/**
 * 弹窗时机类型
 */
export const PopupTimeTypeEnum = [
	/** 访问主页 */
	'HOME',
	/** 用户充值 */
	'RECHARGE',
	/** 首次提现 */
	'FIRST_WITHDRAWAL',
] as const
export type TPopupTimeTypeEnum = (typeof PopupTimeTypeEnum)[number]

/**
 * 安装方式
 */
export const PopupInstallTypeEnum = ['PWA', 'APK', 'PWA+APK'] as const
export type TPopupInstallTypeEnum = (typeof PopupInstallTypeEnum)[number]

/**
 * 稽核副表数据状态
 */
export const AuditSubTableStatusEnum = ['notStarted', 'ongoing', 'completed'] as const
export type TAuditSubTableStatusEnum = (typeof AuditSubTableStatusEnum)[number]

/**
 * 稽核解除方式
 */
export const AuditReleaseTypeEnum = ['manual', 'auto', 'balanceZero', ''] as const
export type TAuditReleaseTypeEnum = (typeof AuditReleaseTypeEnum)[number]

/**稽核解除设置 */
export const AuditReleaseSettingEnum = ['auto', 'notAuto'] as const
export type TAuditReleaseSettingEnum = (typeof AuditReleaseSettingEnum)[number]

/**
 * 稽核主表数据状态
 */
export const AuditMainTableStatusEnum = ['closed', 'unfinished', 'completed'] as const
export type TAuditMainTableStatusEnum = (typeof AuditMainTableStatusEnum)[number]

/**
 * 应用类型
 */
export const AppTypes = [
	/* 苹果H5 */
	'iOSH5',
	/* 安卓H5 */
	'AndroidH5',
	/* PWA */
	'PWA',
	/* APK */
	'APK',
	/* 苹果包 */
	'iOSApp',
	/* 苹果书签 */
	'iOSBookmark',
	/* APK上架包 */
	'APKRelease',
	/* 电脑系统 */
	'DesktopOS',
] as const
export type TAppTypes = (typeof AppTypes)[number]
export const ZAppTypes = z.enum(AppTypes)
/**
 * 游戏配置表发布状态
 */
export const GameConfigPublishStatusEnum = ['published', 'unpublished', 'publishing', 'failed'] as const
export type TGameConfigPublishStatusEnum = (typeof GameConfigPublishStatusEnum)[number]

export const GoogleStyleEnum = ['style_1', 'style_2', 'style_3'] as const
export type TGoogleStyleEnum = (typeof GoogleStyleEnum)[number]

export const AuthenticationEnum = ['google', 'custom'] as const
export type TAuthenticationEnum = (typeof AuthenticationEnum)[number]

export const AgencySoftwareTypeEnum = [
	'Facebook',
	'Telegram',
	'Instagram',
	'TikTok',
	'Whatsapp',
	'Twitter',
	'Kwai',
	'YouTube',
] as const
export type TAgencySoftwareTypeEnum = (typeof AgencySoftwareTypeEnum)[number]

export const FrontConfigSchema = z.object({
	android: z
		.object({
			/** 下载按钮 */
			downloadBtn: z.boolean().default(true),
			/** 引导安装 */
			guideInstall: z.boolean().default(true),
			/** 弹窗类型 */
			popupType: z.enum(PopupContentTypeEnum).default('NORMAL'),
			/** 显示赠送金额 */
			showGiftAmount: z.number().default(0),
			/** 弹窗时间点 */
			popupTime: z.enum(PopupTimeTypeEnum).default('HOME'),
			/** 弹窗间隔时间 */
			popupInterval: z.number().default(0),
			/** 安装类型 */
			installType: z.enum(PopupInstallTypeEnum).default('PWA'),
			/** 安装地址 */
			installUrl: z.string().default(''),
		})
		.default({
			downloadBtn: true,
			guideInstall: true,
			popupType: 'NORMAL',
			showGiftAmount: 0,
			popupTime: 'HOME',
			popupInterval: 0,
			installType: 'PWA',
			installUrl: '',
		}),
	ios: z
		.object({
			/** 下载按钮 */
			downloadBtn: z.boolean().default(true),
			/** 引导安装 */
			guideInstall: z.boolean().default(true),
			/** 弹窗类型 */
			popupType: z.enum(PopupContentTypeEnum).default('NORMAL'),
			/** 显示赠送金额 */
			showGiftAmount: z.number().default(0),
			/** 弹窗时间点 */
			popupTime: z.enum(PopupTimeTypeEnum).default('HOME'),
			/** 弹窗间隔时间 */
			popupInterval: z.number().default(0),
			/** 安装类型 */
			// installType: z.enum(PopupInstallTypeEnum).default('PWA'),
			installType: z.enum(IosPopupContentTypeEnum).default('DESK'),
			/** 安装地址 */
			installUrl: z.string().default(''),
		})
		.default({
			downloadBtn: true,
			guideInstall: true,
			popupType: 'NORMAL',
			showGiftAmount: 0,
			popupTime: 'HOME',
			popupInterval: 0,
			installType: 'DESK',
			installUrl: '',
		}),
})

export const PaymentPartnerTypeEnum = ['Default', 'Custom'] as const
export type TPaymentPartnerTypeEnum = (typeof PaymentPartnerTypeEnum)[number]

/**
 * 推荐活动分类 Custom：自定义 InternalLink：内链接
 */
export const SuggestionActivityTypeSchema = [
	/** 自定义 */
	'Custom',
	/** 内链接 */
	'InternalLink',
] as const

export type TSuggestionActivityTypeSchema = (typeof SuggestionActivityTypeSchema)[number]

/**
 * 追踪条件
 */
export const TrackingConditionsSchema = ['Recharge', 'NotRecharged', 'RechargeBet', 'RechargeNotBet'] as const

export type TTrackingConditionsSchema = (typeof TrackingConditionsSchema)[number]

/**
 * 商户使用类型
 */
export const TenantUseTypeEnum = ['Normal', 'Test'] as const
export type TTenantUseType = (typeof TenantUseTypeEnum)[number]

/**
 * 商户皮肤类型
 */
export const TenantSkinTypeMap = {
	/** 版面1 */
	Layout1: [
		/** 蓝色系 */
		'Blue',
		/** 绿色系 */
		'Green',
		/** 琥珀紫 */
		'AmberPurple',
		/** 蓝色v01 */
		'BlueV01',
		/** 绿色v01 */
		'GreenV01',
		/** 绿色v02 */
		'GreenV02',
	] as const,
	/** 版面2 */
	Layout2: [
		// /** 深翠绿 */
		'DarkGreen',
		// /** 金熠黄 */
		'GoldenYellow',
		// /** 蓝凝紫 */
		'BluePurple',
	] as const,
	/** 版面3 */
	Layout3: [
		// /** 琥珀紫 */
		'AmberPurple',
	] as const,
	/** 版面4 */
	Layout4: [
		/** 棕褐色 */
		'Highlight',
	] as const,
} as const

export type TTenantSkinTypeMap = typeof TenantSkinTypeMap

export type TenantSkinType = keyof typeof TenantSkinTypeMap
export const TenantSkinTypes = typeObjectKeys(TenantSkinTypeMap)

export type TenantSkinSubType = {
	[K in keyof TTenantSkinTypeMap]: TTenantSkinTypeMap[K][number]
}[keyof TTenantSkinTypeMap]

export const TenantSkinSubTypes: [TenantSkinSubType, ...TenantSkinSubType[]] = typeObjectKeys(
	TenantSkinTypeMap,
).flatMap((item: TenantSkinType) => TenantSkinTypeMap[item].map((subItem) => `${item}:${subItem}`)) as [
		TenantSkinSubType,
		...TenantSkinSubType[],
	]

/**
 * 首页类型
 */
export const HomeTypes = [
	/** 游戏类型 */
	'GameType',
	/** 厂商类型 */
	'Platform'
] as const

export type THomeTypes = (typeof HomeTypes)[number]
export const ZHomeType = z.enum(HomeTypes)

/**
 * 导出模块类型
 * @账变记录 AccountChange
 * @会员列表 UserList
 * @用户回访 UserVisit
 * @每日用户数据 UserDayData
 * @会员输赢报表 userDayProfit
 * @会员输赢明细 UserDayProfitDetail
 * @平台每日盈亏 PlatformDailyProfit
 * @平台每日盈亏明细 PlatformDailyProfitDetail
 * @已上分三方充值订单 SuccessPayRecord
 * @未上分三方充值订单 BeCompletedPayRecord
 * @三方充值记录 AllPayRecord
 * @已审核出款订单 WithdrawalRecord
 * @代理首页数据 AgencyHomeList
 * @优惠统计 RewardDiscount
 */
export const ExportModuleTypeEnum = ['AccountChange', 'UserList', 'UserVisit', 'UserDayData', 'userDayProfit', 'UserDayProfitDetail', 'PlatformDailyProfit', 'PlatformDailyProfitDetail', 'SuccessPayRecord', 'BeCompletedPayRecord', 'AllPayRecord', 'WithdrawalRecord', 'AgencyHomeList',
	'AgencyMultipleHomeList', 'RewardDiscount',
] as const
export type TExportModuleTypeEnum = (typeof ExportModuleTypeEnum)[number]
export const ZExportModuleTypeEnum = z.enum(['all', ...ExportModuleTypeEnum])

/**
 * 导出状态类型
 * @导出中 Exporting
 * @导出成功 ExportSuccess
 * @导出失败 ExportFail
 */
export const ExportStatusEnum = ['Exporting', 'ExportSuccess', 'ExportFail'] as const
export type TExportStatusEnum = (typeof ExportStatusEnum)[number]
export const ZExportStatusEnum = z.enum(['all', ...ExportStatusEnum])

/**
 * 导出过期时间类型
 * @永久 Forever
 * @小时 Hour
 * @次数 Times
 */
export const ExportExpireTypeEnum = ['Forever', 'Hour', 'Times'] as const
export type TExportExpireTypeEnum = (typeof ExportExpireTypeEnum)[number]
export const ZExportExpireTypeEnum = z.enum([...ExportExpireTypeEnum])

/**
 * 下载身份类型
 * @导出本人 ExportSelf
 * @不限 Limit
 */
export const ExportIdentityTypeEnum = ['ExportSelf', 'Limit'] as const
export type TExportIdentityTypeEnum = (typeof ExportIdentityTypeEnum)[number]
export const ZExportIdentityTypeEnum = z.enum([...ExportIdentityTypeEnum])

/**
 * 保护策略类型
 * @按时间 Time
 * @按盈利 Profit
 */
export const ProtectionPolicyTypeEnum = ['Time', 'Profit'] as const
export type TProtectionPolicyTypeEnum = (typeof ProtectionPolicyTypeEnum)[number]
export const ZProtectionPolicyTypeEnum = z.enum([...ProtectionPolicyTypeEnum])

/**
 * 保护对象
 * @首充用户 FirstRecharge
 */
export const ProtectionTargetTypeEnum = ['FirstRecharge'] as const
export type TProtectionTargetTypeEnum = (typeof ProtectionTargetTypeEnum)[number]
export const ZProtectionTargetTypeEnum = z.enum([...ProtectionTargetTypeEnum])

/**
 * 执行配置方案
 * @默认 Default
 * @特殊1 Special1
 * @特殊2 Special2
 */
export const ExecutePlanTypeEnum = ['Default', 'Special1', 'Special2'] as const
export type TExecutePlanTypeEnum = (typeof ExecutePlanTypeEnum)[number]
export const ZExecutePlanTypeEnum = z.enum([...ExecutePlanTypeEnum])

/**
 * 玩家保护状态类型
 * @进行中 Ongoing
 * @已完成 Completed
 */
export const PlayerProtectionStatusEnum = ['Ongoing', 'Completed'] as const
export type TPlayerProtectionStatusEnum = (typeof PlayerProtectionStatusEnum)[number]
export const ZPlayerProtectionStatusEnum = z.enum([...PlayerProtectionStatusEnum])

/**
 * 盈利类型
 * @盈利金额 ProfitAmount
 * @盈利比例 ProfitRate
 */
export const ProtectProfitTypeEnum = ['ProfitAmount', 'ProfitRate'] as const
export type TProtectProfitTypeEnum = (typeof ProtectProfitTypeEnum)[number]
export const ZProtectProfitTypeEnum = z.enum([...ProtectProfitTypeEnum])

/**
 * 盈利策略结构
 */
export const ProtectProfitSchema = z.array(
	z.object({
		firstRechargeAmount: z.number().default(0),
		profitType: z.enum(ProtectProfitTypeEnum).default('ProfitAmount'),
		profitAmount: z.number().default(0),
	})
)
export type TProtectProfitSchema = z.infer<typeof ProtectProfitSchema>

/**
 * 地区提款配置
 */
export const RegionWithdrawConfigEnum = [
	/**先绑定 */
	'BindFirst',
	/**先提现 */
	'WithdrawFirst'
] as const
export type TRegionWithdrawConfigEnum = (typeof RegionWithdrawConfigEnum)[number]
export const ZRegionWithdrawConfigEnum = z.enum([...RegionWithdrawConfigEnum])

/*
 * 短信供应商
 */
export const SMSMap = {
	/** 畅亿云 */
	ChangYiYun: {
		name: '畅亿云',
		code: 'ChangYiYun',
		url: 'http://101.44.162.101:9090',
		defaultTemplate: 'Your verification code is {code}. Please enter it within 10 minutes.',
		params: ['appId', 'appKey', 'secret'],
	},
	/** TexcellSMS Group */
	TexcellSMS: {
		name: 'TexcellSMS',
		code: 'TexcellSMS',
		url: 'http://61.244.118.70:20003',
		defaultTemplate: 'Your verification code is {code}. Please enter it within 10 minutes.',
		params: ['appId', 'secret'],
	},
	/** Maco */
	Maco: {
		name: 'Maco',
		code: 'Maco',
		url: 'http://47.242.85.7:9090',
		defaultTemplate: 'Your verification code is {code}. Please enter it within 10 minutes.',
		params: ['appId', 'appKey', 'secret'],
	},
	/** Itniotech */
	Itniotech: {
		name: 'Itniotech',
		code: 'Itniotech',
		url: 'https://api.itniotech.com',
		defaultTemplate: 'Your verification code is {code}. Please enter it within 10 minutes.',
		params: ['appKey', 'secret', 'appId'],
	},
} as const satisfies Record<string, {
	// 名称
	name: string
	// Code
	code: string
	// URL
	url: string
	// 默认短信模版
	defaultTemplate: string
	// 需要参数
	params: string[]
}>

export type TSMSType = keyof typeof SMSMap
export const TSMSTypes = typeObjectKeys(SMSMap)
export const ZTSMSTypes = z.enum(TSMSTypes)

/**设置提现密码校验类型 */
export const ZWithdrawPWAuthType = z.enum(['NONE', 'LOGIN_PASSWORD', 'PHONE', 'EMAIL'])


/**
 *  banenr 类型
 */
export const BannerTypes = [
	/** 大厅推荐 */
	'lobby_banner',
	/** 轮播图 */
	'lobby_carousel',
	/** 侧边栏推荐 */
	'lobby_sidebar_banner',
] as const

export type TBannerTypes = (typeof BannerTypes)[number]

/**
 * 目标类型
 */
export const TargetTypes = [
	/** 不跳转 */
	'none',
	/** 内部链接 */
	'internal',
	/** 外部链接 */
	'external',
] as const

export type TTargetTypes = (typeof TargetTypes)[number]
export const ZTargetTypes = z.enum(TargetTypes)

/*
 * 批量 状态
 */
export const TargetValueList = [
	/** 活动 */
	'activity',
	/** 充值 */
	'recharge',
	/** 提现 */
	'withdraw',
	/** 推广 */
	'promotion',
	/** 活动列表页 */
	'activity_list',
	/** VIP */
	'vip',
	/** 兑换码 */
	'redeem_code',
	/** 首页 */
	'home',
	/** 游戏 */
	'game',
] as const

export type TTargetValueList = (typeof TargetValueList)[number]
export const ZTargetValueList = z.enum(TargetValueList)


/**
 * 用户关注类型
 */
export const UserFollowTypeEnum = ['auditAnomaly'] as const
export type TUserFollowTypeEnum = (typeof UserFollowTypeEnum)[number]
export const ZUserFollowTypeEnum = z.enum(UserFollowTypeEnum)

/**
 * 自动代付其他免审条件
 */
export const AutoPayOtherConditions = [
	//充提差额大于0的会员才免审核
	'MembersWithPositiveDepositWithdrawalDifference',
	//恶意刷稽核未处理的会员继续审核
	'MembersWithUnresolvedMaliciousAudits',
	//近3天有系统解除稽核的会员必须审核
	'MembersWithAuditReliefInLast3Days',
	//历史累计亏损会员才免审核
	'MembersWithCumulativeLosses',
	//已完成首充会员才免审
	'MembersWhoCompletedFirstDeposit'
] as const
export type TAutoPayOtherConditions = (typeof AutoPayOtherConditions)[number]
export const ZAutoPayOtherConditions = z.enum(AutoPayOtherConditions)
//==========================================================================================================
// 动态奖池配置定义

/**
 * 浮动方式
 */
export const RandomTypes = [
	/** 只增不减 */
	'add',
	/** 只减不增 */
	'reduce',
	/** 增减随机 */
	'random',
] as const

export type TRandomType = (typeof RandomTypes)[number]
export const ZRandomTypes = z.enum(RandomTypes)

/**
 * 动态奖池配置定义
 */
export const DynamicPrizePoolConfigSchema = z.object({
	/** 奖池开关 */
	switch: z.boolean().default(false).describe('奖池开关'),
	/** 每日重置初始值 最小值 */
	initValueMinByDay: z.number().default(0).describe('每日重置初始值 最小值'),
	/** 每日重置初始值 最大值 */
	initValueMaxByDay: z.number().default(0).describe('每日重置初始值 最大值'),
	/** 浮动方式 */
	randomType: z.enum(RandomTypes).default('add').describe('浮动方式'),
	/** 每次浮动值 最小值*/
	randomValueMin: z.number().default(0).describe('每次浮动值 最小值'),
	/** 每次浮动值 最大值*/
	randomValueMax: z.number().default(0).describe('每次浮动值 最大值'),
	/** 每次浮动间隔时间 */
	// randomInterval: z.number().default(0).describe('每日重置初始值 间隔时间 单位是：分钟'),
	/** 背景底图 */
	background: z.string().default('').describe('背景底图'),
	/** 预览文本 */
	previewText: z.string().default('').describe('预览文本'),
})

export type TDynamicPrizePoolConfig = z.infer<typeof DynamicPrizePoolConfigSchema>




/**
 * 分控类型
 */
export const RiskTypesEnum = [
	/** 恶意刷稽核 */
	'MaliciousAudit',
] as const
export type TRiskTypesEnum = (typeof RiskTypesEnum)[number]
export const ZRiskTypesEnum = z.enum(RiskTypesEnum)


/**
 * 风控状态
 */
export const RiskStatusEnum = [
	/** 风控中 */
	'RiskControl',
	/** 解除 */
	'Release',
] as const
export type TRiskStatusEnum = (typeof RiskStatusEnum)[number]
export const ZRiskStatusEnum = z.enum(RiskStatusEnum)
/**
 * 事件状态
 */
export const EventStatusEnum = [
	/**
	 * 进入队列
	 */
	'Queued',
	/**
	 * 运行中
	 */
	'Running',
	/**
	 * 取消
	 */
	'Cancelled',
	/**
	 * 已完成
	 */
	'Completed',
	/**
	 * 等待重试
	 */
	'WaitingRetry',
	/**
	 * 失败
	 */
	'Failed',
] as const
export type TEventStatusEnum = (typeof EventStatusEnum)[number]
export const StepStatusEnum = EventStatusEnum
export type TStepStatusEnum = (typeof StepStatusEnum)[number]
